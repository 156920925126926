.filetype-icon {
    --size:64px;
    --color:#AAAAAA;
    --flag-radius:.5em;
    --icon:url(../app-assets/filetype-default.png);
  
    font-size:calc(var(--size) / 4);
    display:inline-block;
    height:4em;
    width:3em;
    margin:1em;
    background:#eef;
    border-radius:0.2em;
    box-shadow:0px 0px 4px #0006;
    position:relative;
  
    &[data-type="text"] {
      --color:#AAAACC;
      --icon:url(../app-assets/filetype-text.png);
    }
  
    &[data-type="pdf"] {
      --color:#E63F32;
      --icon:url(../app-assets/filetype-pdf.png);
    }
    &[data-type="image"] {
      --color:#1982C4;
      --icon:url(../app-assets/filetype-image.png);
    }
    &[data-type="design"] {
      --color:#6A4C93;
      --icon:url(../app-assets/filetype-design.png);
    }
    &[data-type="sound"] {
      --color:#8AC926;
      --icon:url(../app-assets/filetype-sound.png);
    }
    &[data-type="video"] {
      --color:#8AC926;
      --icon:url(../app-assets/filetype-video.png);
    }
    &[data-type="document"] {
      --color:#2D5792;
      --icon:url(../app-assets/filetype-document.png);
    }
    &[data-type="spreadsheet"] {
      --color:#396A45;
      --icon:url(../app-assets/filetype-spreadsheet.png);
    }
    &[data-type="presentation"] {
      --color:#C05535;
      --icon:url(../app-assets/filetype-presentation.png);
    }
    &[data-type="archive"] {
      --color:#E2B532;
      --icon:url(../app-assets/filetype-archive.png);
    }
  
    &::after {
      display:block;
      position:relative;
      content:"";
      fill:var(--color);
      background-image:var(--icon);
      width:2.5em;
      height:1.5em;
      background-size:contain;
      background-repeat:no-repeat;
      background-position:50% 50%;
      left:.25em;
      top:.5em;
      z-index:1;
      opacity:0.3;
    }
  
    &::before {
      z-index:0;
      font-size:0.8em;
      font-style:normal;
      position:absolute;
      display:block;
      content:" ";
      background:var(--color);
      filter:brightness(90%);
      bottom:2em;
      left:-.5em;
      height:.5em;
      width:.5em;
      font-family: sans-serif;
      color:#fff;
      border-radius: 0;
      border-top-left-radius: var(--flag-radius);
    }
  
    span {
      z-index:2;
      font-size:0.8em;
      font-style:normal;
      position:absolute;
      display:block;
      background:var(--color);
      bottom:0.5em;
      left:-.5em;
      padding:0 .3em;
      height:1.5em;
      min-width:3.5em;
      text-align:center;
      line-height:1.5em;
      font-family: sans-serif;
      color:#fff;
      border-radius: var(--flag-radius);
      border-top-left-radius: 0;
    }
  }