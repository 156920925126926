#ColorTabs {
	section {
		display:flex;
		flex-direction: row;
		flex-wrap: wrap;

		&.perceptual {
			--ratio:1;
		}

		&.indexed {
			--ratio:0.7;
		}

		div {
			--W:1.7em;
			--w:calc(1.7em * var(--ratio));
			--M:0.2em;
			--m:calc(0.2em + ((var(--W) - var(--w)) / 2));

			width: var(--w);
			height: var(--w);
			border-radius:50%;
			margin:0.2em var(--m);
			padding:0;
			box-shadow: 0 0 2px 0 #0007;
		}
	}
}