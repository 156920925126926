.filter-module[data-filter-type="date"] {
	dt {
		float:left;
		width:200px;
	}
	.ant-select,
	.ant-calendar-picker {
		width:100%;
		max-width:400px;
	}
}