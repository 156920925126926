.zip-history {
	&[data-status="ready"] {
		--color:#0CC80099;
	}
	&[data-status="processing"] {
		--color:#60b2ff;
		.zip-history-item {
			pointer-events: none !important;
			cursor:initial;
			opacity: 0.5;
		}
	}
	&[data-status="error"] {
		--color:tomato;
		.zip-history-item {
			pointer-events: none !important;
			cursor:initial;
			opacity: 0.5;
		}
	}

	& {
		position: relative;
		border-left:5px solid var(--color);
		margin:4px 0 !important;
		overflow: hidden;
		background:#f6f6f6;
	}

	&:last-child {
		border-bottom:none;
	}

	.zip-history-item {
		text-wrap: initial !important;
		text-wrap: balance !important;
		display: grid;
		grid-column-gap: 5px;
		grid-column: auto auto 1fr;
		grid-template-areas:
			"create   create  expire  expire"
			"content  content content ."
			"filesize detail detail ."
			;

		[data-type="createdAt"] {
			grid-area: create;
			font-size:0.7em;
		}
		[data-type="expireAt"] {
			grid-area: expire;
			color:tomato;
			font-size:0.7em;
			text-align: right;
		}
		[data-type="detail"] {
			grid-area: detail;
			font-size:0.7em;
			opacity: 0.8;
		}
		[data-type="content"] {
			grid-area: content;
		}
		[data-type="filesize"] {
			grid-area: filesize;
			font-size:0.7em;
			opacity: 0.8;
		}
	}
	[data-type="delete"] {
		--size:2em;
		--svgpad:0.4em;
		position: absolute;
		display: block;
		height: var(--size);
		width: var(--size);
		padding:0;
		bottom:0;
		right:9px;
		border-radius:50% 50% 0 50%;
		color:tomato;
		transition: all .15s linear;

		i {
			display:block;
			width:100% !important;
			height:100% !important;
			svg {
				display:block;
				width:100% !important;
				height:90% !important;
				padding: var(--svgpad);
				transition: all .15s linear;
			}
		}

		& {
			background:tomato;
			color:#fff;
			opacity: 0.5;
		}

		&:hover {
			--size:2.5em;
			--svgpad:0.6em;
			background:tomato;
			color:#fff;
			opacity: 1;
		}
	}
}

.zip-history-dropdown {
	padding:0.1px 4px !important;
	max-width: 350px;
}