.hapidam-cpt-filter-bar {
	display:flex;
	flex-direction: row;
	justify-content: flex-start;
	position: relative;
	z-index: 10;
	box-shadow: 0 3px 3px #0003;
	height: var(--toolbar-height);
	line-height: var(--toolbar-height);

	.filter-module {

		.ant-tag {
			margin:4px 2px;
		}

		section.filter-module-tab {
			position: relative;
			z-index: 1;
			padding-left: 1em;
			padding-right: 2.5em;
			border-right:1px solid #0003;
			cursor: pointer;
			.anticon.tab-caret {
				position: absolute;
				right:1em;
				top: 1em;
			}
			&.active {
				color:#40A9FF;
			}

			.tab-extra {
				opacity: 0.7;;
				font-size:0.8em;
				&::before { display:inline-block; content:'('; margin-right:0.2em; }
				&::after { display:inline-block; content:')'; margin-left:0.2em; }
			}
		}

		section.filter-module-panel {
			display: none;
			z-index: 2;
			position: absolute;
			transform: translateY(100%);
			bottom:0;
			width:100%;
			left:0;
			background:#fff;
			padding:1em;
			box-shadow: 0 3px 3px #0003;
		}
		&.opened section.filter-module-panel{
			display: block;

			.filterClear {
				float: right;
			}
		}
	}
}