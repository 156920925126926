.assets_wrapper_mosaic {

    &[data-layout="square"]  .assets_card_wrapper {
        aspect-ratio: 1;
    }

    &[data-layout="masonry"],&[data-layout="square"] {

        padding:0.5em;
        --cardHeight:10em; // small by default
        &[data-size="medium"] { --cardHeight:13em; }
        &[data-size="large"] { --cardHeight:16em; }

        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        > * {
            flex: 1 0 auto;
            margin:0.5em;
            min-width: 10%;
            max-width: 100%;
        }

        &::after {
            // prevent growing element on last line
            content: '';
            flex-grow: 100000000;
        }

        // ----------------------------------------------------------------------------------------------------
        // Card (Range/NoRange/Sorted/Unsorted .... may replace all display case)
        // ----------------------------------------------------------------------------------------------------
        .assets_card_wrapper {
            position: relative;
            height: var(--cardHeight); // size scale (to be defined in the CSS scope)
            background:#fff;//rgb(231, 166, 210);
            overflow: hidden;
            border-radius: 0.5em;

            .assets_card_wrapper--folder {
                // sub element displaying multiple thumbnails
                height: var(--cardHeight);
                width: calc(0.9 * var(--cardHeight));
                overflow: hidden;
                display:flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                padding: .5em 1em 2em;
                > img {
                    width: calc(50% - .5em);
                    height: calc(50% - .5em);
                    object-fit:cover;
                    border-radius: 1em;
                    box-shadow: 0 0 5px rgba(0,0,0,0.3);
                }
                .cart-title {
                    text-align: center;
                    position: absolute;
                    bottom: .5em;
                    left: 0;
                    width: 100%;
                }
            }
        
            .item-card-media {
                position: relative;
                height:100%;
                width:100%;
                z-index: 1;
                > img {
                    width: 100%;
                    height: 100%;
                    object-fit:cover;
                }
            }
            .document-icon-container {
                position: absolute;
                top:50%;
                left:50%;
                transform: translate(-50%, -50%);
                z-index: 1;
            }
        
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 50%);
                width: 100%;
                height: 100%;
                z-index: 2;
                opacity: 1;
                pointer-events: none;
            }
            &[data-content="carts"]::after {
                display: none;
            }
        
            %hide-on-bottom {
                z-index: 3;
                transition: all .25s linear;
                opacity: 1;
                transform: translateY(0%);
            }
            %reveal-from-top {
                z-index: 3;
                transition: all .25s linear;
                opacity:0;
                transform: translateY(-100%);
            }
            %reveal-from-bottom {
                z-index: 3;
                transition: all .25s linear;
                opacity:0;
                transform: translateY(100%);
            }
            &:hover,&.hovered {
                &::after {
                    background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 50%);
                }
                %hide-on-bottom {
                    opacity: 0;
                    transform: translateY(100%);
                }
                %reveal-from-top {
                    opacity:1;
                    transform: translateY(0%);
                }
                %reveal-from-bottom {
                    opacity:1;
                    transform: translateY(0%);
                }
            }
        
            &.card-selected::after  {
                background: linear-gradient(180deg, rgba(48, 125, 197, 0.7) 0%, rgba(48, 125, 197, 0.3) 50%, rgba(48, 125, 197, 0.7) 100%) !important;
            }

            .item-card-title {
                @extend %hide-on-bottom;
                position:absolute;
                bottom:0;
                left:0;
                margin: 0;
                padding: 1em 1em 3px;
                font-size: 0.9em;
                color:white;
                word-break: break-word;
                width: 100%;
                &::after {
                    // bottom white line
                    display: block;
                    width:50%;
                    margin:auto;
                    content: '';
                    border-bottom: 1px solid #fff9;
                    
                }
                .item-card-title--ext {
                    font-size: 0.7em;
                    display: inline-block;
                    background: white;
                    border-radius: 0.5em;
                    color: #333;
                    word-break: normal;
                    padding:0.4em 0.6em;
                    line-height: 1em;
                    vertical-align: middle;
                    text-transform: uppercase;
                }
                .item-card-title--name {
                    display: block;
                    margin:0.5em 0 1em;  
                    line-height: 1.1em;
                }
            }
            
            .item-card-checkbox {
                @extend %reveal-from-top;
                position:absolute;
                top:0.4em;
                left:0.4em;
                line-height: 1em;
                .ant-checkbox-inner {
                    border-radius: 0.25em;
                }
            }
            .item-card-fullscreen {
                @extend %reveal-from-top;
                position: absolute;
                color: white;
                top: 0.4em;
                right: 0.4em;
            }

            &.isWidget .item-card-actions-wrapper {
                display: inline-block !important;
                width: fit-content !important;
            }

            .item-card-actions-wrapper {
                @extend %reveal-from-bottom;
                position: absolute;
                color: white;
                bottom:0;
                left:0.5em;
                width:calc(100% - 1em);
                .item-card-actions {
                    margin:0 auto .5em;
                    max-width:9em;
                    background:white;
                    color:#333;
                    border-radius: 0.5em;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    padding:0.2em 0.6em 0;
                    font-size:1.1em;
                    line-height: 1.5em;
                    > * {
                        margin-right: 0.6em;
                    }

                    .ant-btn-link {
                        font-size:1em;
                        color:inherit !important;
                        border:none;
                        padding:0;
                        margin-right: 0;
                        margin-left: auto;
                    }

                    .ant-badge {
                        font-size:1em;
                        margin:.5em 0.6em .5em 0;
                    }
                    .ant-badge-count {
                        scale:0.6;
                        background-color: #1890FF;
                    }
                }
            }
            
        }
    }
}

