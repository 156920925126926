.selectable-container {
  background-color: #eee;
}

.light {
  font-size: .8em;
  color: #aaa;
}

.spin-fullscreen-centered {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.asset-card:hover .see-on-hover {
  opacity: 1;
}

.item-card:hover .document-icon.aside {
  display: none;
}

.item-card:hover>.see-on-hover {
  opacity: 1;
}

.document-icon {
  display: inline-block;
  z-index: 2;
}

.see-on-hover {
  transition: opacity 0.3s ease-in;
  opacity: 0;
}

.item-card:hover>.hide-on-hover {
  display: none;
}

.asset-card.light {
  position: relative;
  width: 14%;
  margin: 15px Calc(10% / 10);
  box-shadow: 0px 0px 13px -5px rgba(0, 0, 0, 0.75);
}

.asset-card.light.covered {
  img.thumb-classical {
    object-fit: cover;
  }
}

.logo_fs_range.see-on-hover {
  top: 34px;
  font-size: 1em;
  padding: 7px;
}

.logo_fs_range.black {
  background-color: rgba(0, 0, 0, 0.65);
  color: rgba(255, 255, 2550, 0.8);
}

.logo_fs_range.full-top-right {
  top: 0;
  right: 0;
}

.search-mobile-option {
  width: 100%;
  padding: 1em 0;
  cursor: pointer;
}

.global-search {
  min-width: 250px;
}

.asset-actions {
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.65);
  color: white;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 10px;

  & p {
    font-size: 12px;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    overflow: hidden;
  }
}

.account-list-item {
  padding: 8px;
  &:hover, &.selected {
    background-color: #e6f7ff;
    color: #1890ff
  }

  &.group-item {
    border-bottom: 1px solid #e8e8e8;
  }

}

//Sidebar

.search-bar-side {
  & .ant-layout-sider-children {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  & .ant-spin-nested-loading,
  .ant-spin-container {
    height: auto;
  }
}

.sidebar {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: sticky;
  top: 0;
  left: 0;
  border-right: 1px solid #e8e8e8;
}

.sidebar-logo {
  border-bottom: 1px solid #e8e8e8;
  height: var(--header-height);
  display: flex;
  text-align: center;
  align-items: center;

  & img {
    width: 70%;
    height: var(--header-height);
  }
}

.sidebar-menu {
  height: var(--toolbar-height);
  min-height: var(--toolbar-height);
  padding: 0 1em;
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  justify-content: space-between;

  .sidebar-menu-button {
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    // background-color: #f4f4f4;
    color: #1890ff;

    &.mobile-close-menu {
      display: none;
    }

    &.active {
      background-color: #1890ff;
      color: #fff;
    }

    & i {
      font-size: 18px;
    }

    &:hover {
      box-shadow: 0 0px 5px rgb(0 0 0 / 30%);
      z-index: 2;
    }
  }
}

.sidebar-content,
.sidebar-filters,
.tag-list {
  position: relative;
  flex-grow: 1;
  overflow: auto;
  height: calc(100vh - 95px);

  &.no-header {
    height: calc(100vh - 40px);
  }

  &::-webkit-scrollbar {
    width: 0.775em;
    height: 0.875em;
  }

  &::-webkit-scrollbar-thumb {
    border: solid 0 transparent;
    border-right-width: 0.25em;
    border-radius: 0.3125em;
    border-top-right-radius: 0.5625em 0.3125em;
    border-bottom-right-radius: 0.5625em 0.3125em;
    box-shadow: inset 0 0 0 0.0625em rgb(140, 140, 140),
      inset 0 0 0 0.375em rgb(150, 150, 150);
  }

  &::-webkit-scrollbar-thumb:hover {
    /* Barre */
    box-shadow: inset 0 0 0 0.0625em rgb(90, 90, 90),
      inset 0 0 0 0.375em rgb(110, 110, 110);
  }

  .sidebar-title {
    overflow: hidden;
    height: 30px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    line-height: 30px;

    &:hover {
      background-color: #f4f4f4;
    }

    &.selected {
      background-color: skyblue;
      color: white;
    }
  }

  .sidebar-button {
    text-align: center;
    height: calc(var(--toolbar-height) + 4px);
    line-height: var(--toolbar-height);
    color: #1890ff;
    margin: 0 auto;
    border-bottom: 4px solid #ccc;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 0 5px 1px #f2f2f2;

    &.warning {
      color: #faad14;
      border-bottom: 4px solid #faad14;
    }

    &:hover {
      box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    }

    &.selected {
      background-color: skyblue;
      color: white;
      border-right: 4px solid skyblue;
    }
  }


}

.tag-list {
  max-height: 200px;
  height: auto;
}

.sidebar-filters {
  height: calc(100vh - 95px);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(-100%);
  transition: 0.3s;
  background: #fff;
  z-index: 15;
  overflow: auto;

  &.visible {
    transform: translateX(0);
  }
}

.assets-number {
  margin-left: 8px;
  margin-right: 5px;
}

.assets-container {
  overflow: hidden;
  height: calc(100vh - var(--header-height));
  position: relative;
}

.opacity-medium {
  opacity: 0.5;
}

span.light {
  font-size: 0.8em;
}

.media-page {
  display: flex;

  &.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.90);

    &.png_bg {
      background-image: repeating-conic-gradient(rgb(179 179 179 / 50%) 0% 25%,
          rgb(115 115 115 / 50%) 0% 50%);
      background-position: 0 0, 24px 24px;
      background-size: 48px 48px;
      background-color: #ffffff;
    }

    .media-sidebar {
      transform: translateX(100%);
      position: fixed;
      right: 0;
      transition: 0.3s;
    }

    .media-container {
      width: 100%;
      transition: 0.3s;

      & #pdf-download {
        display: none;
      }

      & .asset-name {
        color: #fff;
        position: absolute;
        bottom: .5em;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);
      }
    }
  }

  .react-pdf__Document,
  .react-pdf__Page__canvas {
    border-radius: .5em;
    max-height: 80vh;
    object-fit: contain;
    max-width: 95%;
    margin: 0 auto;
  }

  .media-container {
    padding: 4em 2em;
    height: 100%;
    position: relative;
    width: Calc(100% - 350px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .media-sidebar {
    height: 100%;
    max-height: 100%;
    overflow: auto;
    width: 350px;
    position: relative;
    background: #fff;
  }

  &.isActive {
    .media-sidebar {
      transform: translateX(0);
    }

    .media-container {
      width: Calc(100% - 350px);
    }
  }
}

.media {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.stats-card {
  margin: 1em 0;
}

.galery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5em;
  padding: 1em;
  grid-auto-rows: min-content;

  & .picture-name {
    color: #fff;
    text-align: center;
    text-transform: capitalize;
    padding:0 0.2em 0.2em;
  }

  .item-card {
  	border:1px solid #fff3;

    &:hover {
      border:1px solid #fff7;
    }
  }
}

//document

.graph-container {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  & .flex {
    margin: 1em;
  }
}

.has-sidebar,
.has-sidebar.widget {
  min-height: Calc(100vh - 64px);
}


// VISITOR

.visitor-logo {
  max-width: 200px;
  display: flex;
  align-items: center;

  & img {
    object-fit: contain;
    max-height: 85%;
  }
}

.visitor-hero {
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e9e9e9;
    background-position: center;
    background-size: cover;

    & .visitor-search {
      width: 400px;
    }
}

.visitor-breadcrumb {
  padding: 1em 2em;

  & hr {
    border-top: 2px solid #8d8d8d
  }
}

// RESPONSIVE 

@media (min-width: 1900px) {
  .galery {
    max-width: 90%;
  }
}

@media (max-width: 1350px) {

  .document_container {
    width: 16.6%;

    .document {
      .iconDoc {
        font-size: 8em;
      }
    }
  }
}

@media (max-width: 1024px) {

  .no-tablet {
    display: none;
  }

  .assets-container {
    height: calc(100vh - 64px);
  }

  .assets-square-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .has-sidebar {
    min-height: 100vh;
  }

  .see-on-hover {
    opacity: 1;
  }

  .search-bar-side {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    flex: 0 0 300px;

    .sidebar-menu {
      .mobile-close-menu {
        display: flex;
      }
    }
  }

  .galery-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .global-search {
    width: 100%;
    // padding: 0.5em 0;
  }

  .color-panel {
    width: 60%;
  }

  .request-container {
    .request-content {
      width: 100%;
    }

    .request-preview {
      width: 100%;
    }
  }

  .document_container {
    width: 20%;

    .document {
      .iconDoc {
        font-size: 5em;
      }
    }
  }

  .channel-video {
    width: 50%;
  }
}

@media (max-width: 768px) {

  .search-bar-side {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    flex: 0 0 100vw;

  }

  .galery-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .classic-text {
    width: 100%;
    font-size: 16px;
  }

  .classic-form {
    width: 100%;
  }

  .tab-topbar {
    // justify-content: center;
    padding: 5px;
  }

  .ant-tabs.ant-tabs-top.ant-tabs-small.ant-tabs-line {
    height: 100vh;

    .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-small-bar {
      padding: 0 24px;
      background-color: white;
      margin: 0;
      z-index: 5;
    }

    .ant-tabs-content.ant-tabs-content-no-animated.ant-tabs-top-content {
      height: Calc(100% - 43px);

      .ant-tabs-tabpane.ant-tabs-tabpane-active {
        height: 100%;
        overflow: auto;
      }
    }
  }

  .ant-input-search.searchbar .ant-input {
    font-size: 16px;
  }

  .ant-tabs.ant-tabs-top.ant-tabs-large.ant-tabs-line {
    height: 100vh;
  }

  a h2 {
    font-size: 1.2em;
  }

  .ant-card-meta-avatar {
    display: none;
  }

  .dashboard {
    padding: 24px 10px;
  }

  .no-mobile {
    display: none;
  }

  .color-panel {
    width: 80%;
  }

  .create-account-card {
    width: 90%;
    margin: auto;
  }

  .more-button {
    margin: 1em auto;
  }

  .mobile-sticky-top {
    position: sticky;
    top: 0;
    z-index: 21;
  }

  .ant-input {
    font-size: 16px;
  }

  .document_container {
    width: 25%;

    .document {
      .iconDoc {
        font-size: 3em;
      }
    }
  }

  .channel-video {
    width: 100%;
  }
}

@media (max-width: 600px) {

  .ant-modal-body {
    padding: 24px 1em;
  }

  .modal_img {
    min-width: inherit;
    width: 90%;
  }

  .chevron.right {
    right: 15px;
  }

  .chevron.left {
    left: 15px;
  }

  .document_container {
    width: 33%;

    .document {
      .iconDoc {
        font-size: 3em;
      }
    }
  }
}

@media (max-width: 500px) {
  .document_container {
    width: 50%;

    .document {
      .iconDoc {
        font-size: 3em;
      }
    }
  }
}