.CoiAdjuster_wrapper {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	button {
		line-height: 1.7em;
	}
}

.CoiAdjuster_picture {
	position:relative;
	display:inline-block;
	font-size:0;
}
.CoiAdjuster_picture img {
	pointer-events:none;
}
.CoiAdjuster_picture::before,.CoiAdjuster_picture::after {
	position:absolute;
	pointer-events:none;
	display:block;
	content:"";
	top:0;
	left:0;
	height: 100%;
	width: 100%;
}
.CoiAdjuster_picture::before {
	border-top:2px dotted tomato;
	top:var(--y);
}
.CoiAdjuster_picture::after {
	border-left:2px dotted tomato;
	left:var(--x);
}

.CoiAdjuster_wrapper img {
	margin:5px;
}