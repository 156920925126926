body {
  height: 100%;

  --toolbar-height:3em;
  --header-height:4em;
}

// Update antd class

.ant-btn {
  line-height: 1em;
}

.ant-tag {
  margin: 5px 10px;

  .isFiltering {
    font-weight: bold;
    border: 1px solid orange;
    box-shadow: orange 0px 0px 3px;
  }
}

.ant-tag:hover {
  cursor: pointer;
}


//Generic classes
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.center {
  justify-content: center;
}

.align_center {
  align-items: center;
}

.align_baseline {
  align-items: baseline;
}

.between {
  justify-content: space-between;
}

.around {
  justify-content: space-around;
}

.end {
  justify-content: flex-end;
}

.wrap {
  flex-wrap: wrap;
}

.radius {
  border: 1em;
}

.m-0 {
  margin: 0;
}

.m {
  margin: 1em;
}

.m-20 {
  margin: 20px;
}

.m-10 {
  margin: 10px;
}

.mt-50 {
  margin-top: 50px;
}

.mt {
  margin-top: 1em;
}

.mt-sm {
  margin-top: .5em;
}

.mt-20 {
  margin-top: 20px;
}

.mt-5 {
  margin-top: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml {
  margin-left: 1em;
}

.ml-sm {
  margin-left: .5em;
}

.mr-5 {
  margin-right: 5px;
}

.mr-sm {
  margin-right: .5em;
}

.mr {
  margin-right: 1em;
}

.mb {
  margin-bottom: 1em;
}

.mb-sm {
  margin-bottom: .5em;
}

.mb-large {
  margin-bottom: 2em;
}

.padding-10 {
  padding: 10px;
}

.pt {
  padding-top: 1em;
}

.pl-20 {
  padding-left: 20px;
}

.w-250 {
  width: 250px;
}

.w-100per {
  width: 100%;
}

.h-100per {
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-capitalize {
  text-transform: capitalize;
}

.loading_container_virgin {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
}

.asset-status {
  .ant-tag {
    margin: 3px;
  }
}

.hidden {
  display: none;
}

.bold {
  font-weight: bold;
}

.my_dropdown {
  padding: .5em;
  overflow: auto;
  min-width: inherit;
  max-width: 500px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  ::-webkit-scrollbar {
    width: 0.775em;
    height: 0.875em;
  }

  ::-webkit-scrollbar-thumb {
    border: solid 0 transparent;
    border-right-width: 0.25em;
    border-radius: 0.3125em;
    border-top-right-radius: 0.5625em 0.3125em;
    border-bottom-right-radius: 0.5625em 0.3125em;
    box-shadow: inset 0 0 0 0.0625em rgb(140, 140, 140),
      inset 0 0 0 0.375em rgb(150, 150, 150);
  }

  ::-webkit-scrollbar-thumb:hover {
    /* Barre */
    box-shadow: inset 0 0 0 0.0625em rgb(90, 90, 90),
      inset 0 0 0 0.375em rgb(110, 110, 110);
  }

  ul {
    padding: 0;
    margin: 0 auto;
  }

  li:hover {
    background-color: #e2e2e2;
  }
}

.my_dropdown.dl_dr {
  text-align: left;
}

.square-btn {
  width: 3em;
  height: 3em;
  padding: 0;
  overflow: hidden;
}

.png_transparent {
  background-color: rgb(190, 190, 190);
  background-image: linear-gradient(45deg,
      rgb(120, 120, 120) 25%,
      transparent 25%,
      transparent 75%,
      rgb(120, 120, 120) 75%,
      rgb(120, 120, 120)),
    linear-gradient(45deg,
      rgb(120, 120, 120) 25%,
      transparent 25%,
      transparent 75%,
      rgb(120, 120, 120) 75%,
      rgb(120, 120, 120));
  background-size: 10px 10px;
  background-position: 0 0, 5px 5px;
}

.bg-pattern-dot {
  background-color: #e6e6e6;
  background-image: linear-gradient(135deg, #b8b8b8 25%, transparent 25%), linear-gradient(225deg, #b8b8b8 25%, transparent 25%), linear-gradient(45deg, #b8b8b8 25%, transparent 25%), linear-gradient(315deg, #b8b8b8 25%, #e6e6e6 25%);
  background-position: 13px 0, 13px 0, 0 0, 0 0;
  background-size: 13px 13px;
  background-repeat: repeat;
}

.bg-pattern-square {
  background-color: #e6e6e6;
  background-image: repeating-linear-gradient(45deg, #cbcbcb 25%, transparent 25%, transparent 75%, #cbcbcb 75%, #cbcbcb), repeating-linear-gradient(45deg, #cbcbcb 25%, #e6e6e6 25%, #e6e6e6 75%, #cbcbcb 75%, #cbcbcb);
  background-position: 0 0, 13px 13px;
  background-size: 26px 26px;
}

.bg-pattern-iso {
  background-color: #e6e6e6;
  background-image: linear-gradient(30deg, #b8b8b8 12%, transparent 12.5%, transparent 87%, #b8b8b8 87.5%, #b8b8b8), linear-gradient(150deg, #b8b8b8 12%, transparent 12.5%, transparent 87%, #b8b8b8 87.5%, #b8b8b8), linear-gradient(30deg, #b8b8b8 12%, transparent 12.5%, transparent 87%, #b8b8b8 87.5%, #b8b8b8), linear-gradient(150deg, #b8b8b8 12%, transparent 12.5%, transparent 87%, #b8b8b8 87.5%, #b8b8b8), linear-gradient(60deg, #b8b8b877 25%, transparent 25.5%, transparent 75%, #b8b8b877 75%, #b8b8b877), linear-gradient(60deg, #b8b8b877 25%, transparent 25.5%, transparent 75%, #b8b8b877 75%, #b8b8b877);
  background-size: 26px 46px;
  background-position: 0 0, 0 0, 13px 23px, 13px 23px, 0 0, 13px 23px;
}

.bg-pattern-white {
  background-color: #fff !important;
}

.form_center {
  width: 500px;
  margin: 20px auto;
}

// Header

.header {
  border-bottom: 1px solid #e8e8e8;
  background-color: white;
  padding: 0 24px;
  z-index: 5;
  width: 100%;
  position: sticky;
  top: 0;
  height: var(--header-height);
  line-height: var(--header-height);
}

.ant-input-search.ant-input-search-enter-button.ant-input-group-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.certain-search-item-count {
  position: absolute;
  color: #999;
  right: 16px;
}

.ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-right: none;
}

.group-view-tag {
  bottom: 0;
  position: absolute;
  font-size: 10px;
  margin: 0;
  line-height: initial;
}

//Sidebar

.search-bar-side {
  display: flex;
  flex-direction: column;
  background: #fff;
  height: 100%;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  flex: 0 0 300px;
  overflow-x: hidden;
  overflow-y: auto;
  position: sticky;
  top: 0;
  left: 0;

  border-right: 1px solid #ddd;
  .ant-layout-sider-children .ant-menu-inline {
    border-right: none;
  }
}

.sidebar-layout.ant-layout-sider.ant-layout-sider-light {
  border-right: 1px solid #e8e8e8;
  height: Calc(100vh - 64px);
  position: fixed;
  left: 0;
}

.sidebar-layout.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger {
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-layout .ant-card {
  .username {
    margin-left: 0.5em;
    text-transform: capitalize;
    display: inline-block;
  }
}

// Assets

.assets-list {
  height: Calc(100% - 2 * var(--toolbar-height));
  overflow: auto;
  user-select: none;

  &.no-active-bar {
    height: 100%;
  }
}

.video-placeholder {
  position: relative;
  background-color: #2a2a2a;
  text-align: center;
  color: white;
  height: 100%;
}

.document_container {
  width: 12.5%;
  padding: 0 10px 10px 10px;

  .document {
    position: relative;
    text-align: center;
    overflow-wrap: break-word;
    background-color: #fff;
    cursor: pointer;

    .iconDoc,
    .nameDoc {
      padding: 10px;
    }

    .nameDoc {
      height: 62px;
    }
  }
}

.channel-video {
  width: 33%;
  padding: 10px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  /* ratio 16/9 */
  height: 0;
  overflow: hidden;
  clear: both;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Modal
.collection-create-form_last-form-item {
  margin-bottom: 0;
}

.change-upload {
  cursor: pointer;
  color: #1890ff;
  margin: 1em 0;
}

.upload-item {
  color: #AFB0D6;
  border: solid 1px #DFE3F2;
  border-radius: 6px;
  padding: 0.5em 1em;

  .upload-title {
    display: flex;
    justify-content: space-between;
  }
}

.example-tree {
  max-height: 160px;
  width: 100%;
  overflow-y: auto;
  border: 1px solid #ddd;
  margin-top: 10px;
}

// Test Selections multiple

.card-selected {
  box-shadow: #1890ff 0 0 5px !important;
}

.color-panel {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 15px;

  .centered-icon {
    opacity: 0;
  }

  .color-circle {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin: 5px;
    position: relative;
    cursor: pointer;

    &:hover .centered-icon {
      opacity: 1;
    }
  }
}

.centered-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.facebook {
  color: #4267b2;
}

.instagram {
  color: #a83cbe;
}

.linkedin {
  color: #2d73b0;
}

.social {
  transition: transform 0.3s ease;
}

.social:hover {
  transform: scale(1.1);
}

#image_social_modal {
  max-height: 80vh;
}

.stats-card:hover {
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
}

.stats-card.active {
  border-color: #1890ff;
}

//Tabs

.contenu-tab {
  margin: 24px 16px;
  padding: 24px;
  background: #fff;
  min-height: 280px;
}

.contenu-tab.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
}

.ant-tabs.ant-tabs-top.ant-tabs-large.ant-tabs-line {
  height: Calc(100vh - 64px);

  .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-large-bar {
    padding: 0 24px;
    background-color: white;
    margin: 0;
    z-index: 5;
  }

  .ant-tabs-content.ant-tabs-content-no-animated.ant-tabs-top-content {
    height: Calc(100% - 35px);

    .ant-tabs-tabpane.ant-tabs-tabpane-active {
      height: 100%;
    }
  }
}

.ant-tabs.ant-tabs-top.ant-tabs-small.ant-tabs-line {
  height: Calc(100vh - var(--header-height));

  .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-small-bar {
    padding: 0 24px;
    background-color: white;
    margin: 0;
    z-index: 5;

    .ant-tabs-tab {
      margin-right: 0.5em;
    }
  }

  .ant-tabs-content.ant-tabs-content-no-animated.ant-tabs-top-content {
    height: Calc(100% - 35px);

    .ant-tabs-tabpane.ant-tabs-tabpane-active {
      height: 100%;
    }
  }
}

.ant-tabs-tabpane.ant-tabs-tabpane-active.scrollPan {
  height: 100%;
  overflow: auto;
}

.folderContainer {
  list-style: none;
  padding: 0;
  margin: 0;
}

.folderItem {
  cursor: pointer;
  line-height: 25px;
  font-size: 13px;
  position: relative;

  transition: 0.3s;
  overflow: hidden;
  height: 25px;

  &:hover {
    background-color: #f4f4f4;
  }

  &.isSelected {
    background-color: skyblue;
    color: white;
  }
}

.tab-topbar {
  padding: 5px 24px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  min-height: var(--toolbar-height);
  height: var(--toolbar-height);
  border-bottom:1px solid #ddd;
}

.tab-topbar.action-bar {
  display: none;
  justify-content: space-between;
  text-align: center;
  display:none;
  z-index: 4;
  background-color: #1890ff;

  &.active {
    opacity: 1;
    display: flex;
  }

}

//text selection null

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

// AccountList

.account-brand {
  background-color: #f4f4f4;
  min-height: 100px;
  position: relative;

  .banner-overlay, .avatar-overlay {
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
  }

  .account-banner {
    min-height: 100px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &:hover {
      .banner-overlay {
        display: flex;
      }
    }

  }

  .account-logo {

    position: absolute;
    bottom: 0;
    left: 1em;
    transform: translateY(50%);
    width: 75px;
    height: 75px;
    
    .avatar {
      width: 100%;
      height: 100%;
      border: solid #fff 4px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .avatar-overlay {
      border-radius: 50%;
    }
    &:hover {
      .avatar-overlay {
        display: flex;
      }
    }
  }
}

.account-infos {
  & .info-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;

    & .ant-tag {
      margin: 0;
    }
  }
}

.image-select-filters {
  align-items: end;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 3;
  padding: 1em 0;
}

.table-header {
  margin: 1em 0;
}

.ant-table-body {
  overflow: auto;
}

// Create Account

.create-account {
  padding: 24px;

  h2 {
    text-align: center;
  }
}

.create-account-card {
  margin-top: 1em;
  width: 300px;
}

.more-button {
  width: 128px;
  height: 128px;
  margin: 1em 0;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.5em;
  text-align: center;
}

.ant-card-actions>li>span a:not(.ant-btn),
.ant-card-actions>li>span>.anticon.disabled {
  color: grey;
}

.disabled:hover {
  cursor: not-allowed;
}

.cropSizeTable {
  margin: 1em 0;

  .ant-row.ant-form-item {
    margin: 0;
  }
}

// Login

.login-container {
  border-radius: 1em;
  background-color: #fff;
  width: 350px;
  border-radius: .5em;
  margin: auto;
  padding: 1em;
  text-align: center;

  .logo {
    margin: 1em 1em 2em;

    img {
      width: 85%;
      max-height: 100px;
      object-fit: contain;
    }
  }

  .login-form-forgot {
    float: right;
  }

  .login-form-button {
    width: 100%;
  }
}

// Directories

.ant-tree li span[draggable],
.ant-tree li span[draggable="true"] {
  line-height: 24px;
}

.ant-tree.ant-tree-directory {
  width: 50%;
}

//tree change for easier dragndrop

.ant-tree-treenode-switcher-close.drag-over-gap-bottom,
.ant-tree-treenode-switcher-open.drag-over-gap-bottom,
.ant-tree-treenode-switcher-close.drag-over-gap-top,
.ant-tree-treenode-switcher-open.drag-over-gap-top {

  .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-open.draggable,
  .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal.draggable,
  .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-close.draggable {
    // border-top-color: transparent;
    // border-bottom-color: transparent;
  }
}

.ant-spin-nested-loading {
  height: 100%;

  .ant-spin-container {
    height: 100%;
  }
}

// Gestion Admin

.admin-tag-propositions {
  display: flex;
  flex-wrap: wrap;

  .admin-tag-select {
    width: 100%;
  }
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  word-break: break-word;
  white-space: break-spaces;
}

//
.account-selectable {
  display: flex;
  border-bottom: 1px solid #eee;
  margin: 0 2em;
  padding: 1em 2em;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgb(24, 144, 255, 0.5);
  }
}

// widget builder

.request-page {
  width: 100%;
  background: #f5f7fb;
  padding-bottom: 5em;
  min-height: 100%;
  position: relative;
}

.request-header {
  background-color: #e9eef6;
  padding: 2em;

  .request-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    font-size: 24px;
  }
}

.request-container {
  position: relative;
  background-color: #e9eef6;
  padding: 1em;
  min-height: 200px;
  border-radius: 6px;
  margin-bottom: 2em;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & .request-content {
    width: 75%;
  }

  & .request-preview {
    width: 20%;
  }
}

.request-preview .galery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 8px;
  grid-auto-rows: 1fr;
  row-gap: 8px;
  height: 100%;

  &>div {
    border-radius: 6px;
    cursor: pointer;
  }
}

.gallery-more {
  background-color: #e8e8e8;
  align-self: stretch;
  align-items: center;
  display: flex;
  justify-content: center;
}

.galery-empty {
  border-radius: 6px;
  padding: 1em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: linear-gradient(180deg, #ffffff 0%, #d5d5d5 100%);
}

.bottom-bar {
  box-shadow: 0px -4px 11px rgba(173, 173, 173, 0.16);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 1em;
  background: white;
}

// Responsive 

.mobile-menu {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  gap: 1em;
  width: 100%;
  background: white;
  z-index: 15;
  padding: .5em;
  bottom: 0;
  // border-top: #1890ff 2px solid;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  .import-btn {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
}

.ant-card-head-title {
  padding: 8px 0;
}

// Page Support
.support {
  h1 {
    text-align: center;
    font-weight: bold;
  }

  .anchor_container {
    width: 20%;
  }

  .paragraph {
    width: 80%;
    margin: 0 auto;

    .block_text {
      padding: 20px;

      h2 {
        font-size: 1.1em;
        text-decoration: underline;
      }

      p {
        //padding: 15px 0;
      }

      h3 {
        font-size: 1em;
      }

      .img_container_support {
        display: flex;
        justify-content: center;
        padding: 15px 0;

        img {
          width: 80%;
          max-height: 25vh;
          object-fit: contain;
          filter: drop-shadow(0 0 0.15rem black);
        }
      }
    }
  }
}

.galery {
  margin: auto;
  max-width: 90%;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  .column_galery {
    display: block;
    flex-basis: 10rem;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 1.45rem;

    //padding: 0 4px;
    .img_container {
      width: 100%;
      margin-bottom: 1.45rem;

      img {
        vertical-align: middle;
        width: 100%;
      }
    }
  }
}

.img_container {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;

  & .video-placeholder,
  .document-icon-container {
    height: 200px;
  }

  .is_btn {
    height: 100%;
    cursor: pointer;
  }

  .is_btn:hover {
    background-color: #bbb;
  }
}

.thumbs {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  //align-items: center;
  padding: 30px 4px;

  .img_container {
    margin: 20px;
    background: linear-gradient(to bottom, #fff, #ddd);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
  }
}

.display_on_hover {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.img_container:hover .display_on_hover {
  opacity: 1;
}

.radio_drop {
  display: block;
  padding: .5em;
  width: 100%;
  border-radius: .5em;

  & strong {
    text-transform: capitalize;
  }
}

.radio_drop:hover {
  background-color: #ddd;
}



@media (max-width: 849px) {
  .galery {
    padding: 1rem;

    .column_galery {
      .img_container {
        margin-bottom: 1rem;
      }
    }
  }

  .display_on_hover {
    opacity: 1;
  }
}

// fs card range

.logo_fs_range {
  position: absolute;
  top: 48px;
  right: 1px;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
  font-size: 1.1em;
  padding: 10px;
}

.chevron {
  position: fixed;
  font-size: 30px;
  background-color: #ddd;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  padding: 20px;
  z-index: 10000;
}

.chevron.right {
  right: 150px;
}

.chevron.left {
  left: 150px;
}

.modal_img {
  min-width: 600px;
}



@media (max-width: 849px) {
  .chevron {
    font-size: 20px;
    padding: 15px;
  }

  .chevron.right {
    right: 50px;
  }

  .chevron.left {
    left: 50px;
  }
}

.tree_choice_container {
  position: relative;
  width: 350px;
  padding: 20px;
  border: 1px solid #ddd;

  h3 {
    text-align: center;
  }

  .check_tree {
    position: absolute;
    right: 20px;
  }

  .action_manager {
    position: absolute;
    right: 5px;
  }

  .tree_choice_card {
    width: 300px;
    height: 500px;
    overflow: auto;
  }
}

.classic-text {
  font-size: 20px;
  width: 800px;
  margin: auto;
  text-align: center;
}

.classic-form {
  width: 500px;
  margin: auto;
}