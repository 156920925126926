.gallery-grid-section {
    width: 100%;

    > h2 {
        color:#fff;
        border-bottom: 1px solid #fff;
    }

    .gallery-grid-section-items {
        padding:0.1em;

        display:flex;
        flex-direction: row;
        justify-content: center;

        .gallery-grid-section-item {
            position: relative;
            margin:1em;
            > img {
                max-width: 100%;
                max-height: 10em;
            }
            figcaption {
                color:#fff;
                display: flex;
                justify-content: space-between;
            }
            button {
                position: absolute;
                bottom: 0.2em;
                left: 0.2em;
            }
        }
    }
}