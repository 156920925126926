.uploadMedia-wrapper {

	.ant-upload-drag-hover:not(.ant-upload-disabled) {
		border-color:hsla(207.02, 100%, 62.549%,100%) !important;
		background:hsla(207.02, 100%, 62.549%,10%) !important;
	}

	.ant-upload-select {
		width:100% !important;
		margin:0.5em 0;
	}

}