.action-bar {
	.displacementType {
		.ant-radio-button-wrapper-checked {
			color: white !important;
			background-color: orange !important;
			border-color: #bf7c00 !important;
		}
		.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
			box-shadow: -1px 0 #bf7c00 !important;
		}
	}
}