.widget_v2_assets {
	position: relative;
	height: calc(100vh - var(--header-height));
}

.widget_v2_assets-selection_validate {
	position:absolute;
	bottom: 0;
	left:0;
	width:100%;
	text-align: center;
	z-index: 20;
	padding:1em;
	background:white;
	border-top:1px solid #ddd;
}
