.git-versions-bar {
	background-color: #E6F7FF;
	width:100%;
	padding:0 0 0.2em 0.2em;
	margin:0;
	display:flex;
	justify-content: space-between;
	text-align: left;
	border-top:1px dotted #ddd;
	font-size:1em;
	dl {
		padding:0.5em;
		margin:0;

		&[data-env="server"] { --color:#5765F2; }
		&[data-env="api"] { --color:#FA7B0B; }
		&[data-env="front"] { --color:#3BA45B; }
	}

	dt {
		font-size: 0.9em;
		margin:0;
		padding:0;
		color: var(--color);
	}

	dd {
		font-size: 0.8em;
		margin:0;
		padding:0;
		&[data-type="datetime"] {
			font-size: 0.7em;
			line-height: 1.2em;;
			opacity: 0.8;
		}
	}
}
