div[data-component="Visitor/VisitorAssetList"] {
    .assets-masonry-grid {
        padding:0.5em;
        --cardHeight:10em; // small by default
        &[data-size="medium"] { --cardHeight:13em; }
        &[data-size="large"] { --cardHeight:16em; }

        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        > * {
            flex: 1 0 auto;
            margin:0.5em;
            min-width: 10%;
            max-width: 30%;
        }

        &::after {
            // prevent growing element on last line
            content: '';
            flex-grow: 100000000;
        }
    }

    div[data-component="Visitor/VisitorAssetCard"] {
        position: relative;
        height: var(--cardHeight); // size scale (to be defined in the CSS scope)
        background:#fff;//rgb(231, 166, 210);
        overflow: hidden;
        border-radius: 0.5em;

        .item-card-media {
            position: relative;
            height:100%;
            width:100%;
            z-index: 1;
            > img {
                width: 100%;
                height: 100%;
                object-fit:cover;
            }
        }
    }
}

