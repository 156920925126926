.assets_wrapper_mosaic[data-layout="list"] {

    padding:0.5em;
    --cardHeight:5em; // small by default
    &[data-size="medium"] { --cardHeight:9em; }
    &[data-size="large"] { --cardHeight:13em; }

    padding:1em;

    .assets_card_wrapper {
        position: relative;
        height: var(--cardHeight); // size scale (to be defined in the CSS scope)
        background:#fff;//rgb(231, 166, 210);
        overflow: hidden;
        border-radius: 0.5em;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding:.5em;
        box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
        border-radius: .2em;
        margin-bottom: .5em;

        .assets_card_wrapper--folder {
            
        }

        .item-card-checkbox {
            order: 1;
            padding:0 2em;
            margin:0;
            text-align: center;
        }
    
        .item-card-media {
            order: 2;
            width: calc(var(--cardHeight) - 1em);
            position: relative;
            height:100%;
            z-index: 1;
            background:#eee;
            img {
                height: 100%;
                aspect-ratio: 1/1;
                object-fit: contain;
            }
        }

        .item-card-title {
            order:3;
            padding:0 2em 0 6em;
            margin:0;
            position: relative;

            strong {
                position: absolute;
                display: inline-block;
                background:#eee;
                border-radius: 0.25em;
                padding:.25em .5em;
                left:2em;
                top:50%;
                transform:translateY(-50%);
            }
        }

        .item-card-actions-wrapper {
            order:4;
            flex:auto;
            text-align: right;

            .item-card-actions {
                padding-right: 2em;
                margin-left: auto;
                max-width:9em;
                background:white;
                color:#333;
                border-radius: 0.5em;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                font-size:1.1em;
                > * {
                    margin-right: 0.6em;
                }

                .ant-btn-link {
                    font-size:1em;
                    color:inherit !important;
                    border:none;
                    padding:0;
                    margin-right: 0;
                    margin-left: auto;
                }

                .ant-badge {
                    font-size:1em;
                }
                .ant-badge-count {
                    scale:0.6;
                    background-color: #1890FF;
                }
            }
        }

        .item-card-fullscreen {
            display: none;
        }

        .document-icon-container {
            text-align: center;
        }
        .filetype-icon {
            --size: calc(0.6 * var(--cardHeight) - 0.5em);
        }
        
    }
}
